import React,{useEffect,useRef,useState} from 'react'
import WebTemplate from '../Components/WebTemplate'
import { useGetAllSchoolsQuery} from '../Services/modules/school';
import { Link,useSearchParams  } from 'react-router-dom';
import sainik_shala from "../assets/img/sainik_shala.jpeg";
import GLightbox from 'glightbox';

function ResultLanding() {
  const galleryRef = useRef(null);
 
  const [filter1, setFilter1] = useState('');
  const [filter2, setFilter2] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const { 
    data: schools= [],  
    isLoading:loading=true,
    isSuccess:getSchoolSuccess=false
   } = useGetAllSchoolsQuery();
   useEffect(() => {    
     
    if (getSchoolSuccess) {
       
       
          setFilteredData(schools.data);
        
    }
   
}, [schools]);
useEffect(() => {
  if (galleryRef.current) {
    // Initialize GLightbox
    const lightbox = GLightbox({
      selector: '.glightbox',
      touchNavigation: true, // Enable touch navigation
      loop: true, // Enable looping through images
      closeButton: true, // Show close button
      nextButton: true, // Show next button
      prevButton: true, // Show previous button
    });
  }
}, []);
    const ResultLandingScreen=()=>{  
    
      const handleFormSubmit = (event) => {
        event.preventDefault();
    
        const filteredItems = schools.data.filter((item) => {
          console.log("item filter", item.school_section)
          console.log("item filter", item.medium)
          console.log("filter1",filter1)
          console.log("filter1",filter2)
          return (
            item.school_section === filter1 &&
            item.medium === filter2
          );
        });
    console.log("filteredItems",filteredItems)
        setFilteredData(filteredItems);
       
      };  
      const handleLinkClick = () => {
        setSearchParams({ param1: 'value1', param2: 'value2' });
      }; 
      console.log("All Schools",schools)   
        return(
            <>
           <section class="heroschoolsResult">
  <div class="heroschoolsResult-content">
    <h2 className="text-overlay" style={{color:'#ffffff'}}>परीक्षा निकाल खालील प्रमाणे पहा</h2>
   
  </div> 
</section>


              <main id="main">
              <section id="clients" class="clients section-bg">
      <div class="container">
    <div ref={galleryRef}>
    <div class="row gallery">
    <div class="col-xl-12 col-md-12" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={sainik_shala}
        className="glightbox"
        data-title="Sponge"      
      >
              <div className="course-card">
              <img src={sainik_shala} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           </div>
           </div>

     
        <div class="row" data-aos="zoom-in">
        <div class="alert alert-danger" role="alert" style={{fontSize:"20px"}}>
        गुणवत्ता यादीसह अंतिम निकाल (2024) खालील प्रमाणे जाहीर केला आहे.
</div>
<div class="alert alert-warning" role="alert" style={{fontSize:"20px"}}>
इ.1ली व 2री साठी 54 गुण उत्तीर्ण होण्यासाठी आवश्यक आहेत.
इ.3री ते.7वी साठी दोन्ही विषयात प्रत्येकी कमित कमी 54 व दोन्ही मिळून एकूण गुण 120 गुण उत्तीर्ण होण्यासाठी आवश्यक आहेत.

</div>

         <table className="table">
      <thead>
        <tr>
        <th style={{textAlign:'center'}}>Index</th>
         
          <th style={{textAlign:'center'}}>अंतिम निकाल 2024</th>
         
        </tr>
      </thead>
      <tbody>
      <tr>
            <td>1</td>
            <td>           
            <Link 
            to={{ pathname: '/tseresult'}}
            style={{color:'blue'}}
            >
             
             
गुणवत्ता शोध परीक्षा 2024 अंतिम निकाल (१ली, २री, ३री, ६वी)
              </Link>
              </td>           
          </tr>
          <tr>
            <td>2</td>
            <td>           
            <Link 
            to={{ pathname: '/shatabdiresult'}}
            style={{color:'blue'}}
            >
             
            शताब्दि शिष्यवृत्ती परीक्षा 2024 अंतिम निकाल(४थी व ७वी)
              </Link>
              </td>           
          </tr>
      </tbody>
    </table> 
        

        </div>

      </div>
    </section>
    <section id="services" class="services">
      <div class="container" data-aos="fade-up">

       
        <div class="row">
         
        </div>

      </div>
    </section>
{/* 
<footer id="footer">
<div class="footer-newsletter">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6" >
        <h4 style={{color:'white'}}>Find us On</h4>
        <p>Tamen quem nulla quae legam multos aute sint culpa legam noster magna</p>
        <i class="fa fa-youtube-play fa-3x "
       style={{paddingRight: "1em"}}
         aria-hidden="true"></i>
        <i class="fa fa-spotify fa-3x" 
         style={{paddingRight: "1em"}}
        aria-hidden="true"></i>
        <i class="fa fa-instagram fa-3x" 
         style={{paddingRight: "1em"}}
        aria-hidden="true"></i>
        <i class="fa fa-linkedin fa-3x" 
         style={{paddingRight: "1em"}}
        aria-hidden="true"></i>
        <i class="fa fa-facebook-square fa-3x" 
         style={{paddingRight: "1em"}}
        aria-hidden="true"></i>
      </div>
    </div>
  </div>
</div>
</footer> */}


</main>
            </>
        )
    }
  return (
  <WebTemplate>
    {ResultLandingScreen()}
  </WebTemplate>
  )
}

export default ResultLanding