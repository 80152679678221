import React,{useEffect,useRef,useState} from 'react'
import WebTemplate from '../Components/WebTemplate'
import { useGetAllSchoolsQuery} from '../Services/modules/school';
import { Link,useSearchParams  } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useGetAllCentersQuery, useGetResultMutation } from '../Services/modules/result';
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PersonelResult from '../Components/PersonelResult';
import CenterMeritList from '../Components/CenterMeritList';
import GeneralMeritList from '../Components/GeneralMeritList';
import SchoolwiseResult from '../Components/SchoolwiseResult';
import { useNavigate } from 'react-router-dom';
import qrcode from "../assets/img/qrcode.jpeg";
import GLightbox from 'glightbox';

function ShatabdiResult() {
  const galleryRef = useRef(null);

  const navigate = useNavigate();
  useEffect(() => {
    if (galleryRef.current) {
      // Initialize GLightbox
      const lightbox = GLightbox({
        selector: '.glightbox',
        touchNavigation: true, // Enable touch navigation
        loop: true, // Enable looping through images
        closeButton: true, // Show close button
        nextButton: true, // Show next button
        prevButton: true, // Show previous button
      });
    }
  }, []);
  const goBack = event => {
    navigate('/resultlanding');
     };
    const ShatabdiResultScreen=()=>{  
    
        return(
            <>
             <ToastContainer />
           <section class="heroschoolsResult">
  <div class="heroschoolsResult-content">
    <h2 className="text-overlay" style={{color:'#ffffff'}}>शताब्दी शिष्यवृत्ती परीक्षा (2023) निकाल खालील प्रमाणे पहा</h2>
   
  </div> 
</section>


              <main id="main">
              <section id="clients" class="clients section-bg">
      <div class="container">
      <div class="alert alert-danger" role="alert">
      शताब्दि शिष्यवृत्ती परीक्षा- गुणवत्ता यादीसह अंतिम निकाल (2024) खालील प्रमाणे जाहीर केला आहे.
</div>
<div class="alert alert-warning" role="alert" style={{fontSize:"20px"}}>
इ.1ली व 2री साठी 54 गुण उत्तीर्ण होण्यासाठी आवश्यक आहेत.
इ.3री ते.7वी साठी दोन्ही विषयात प्रत्येकी कमित कमी 54 व दोन्ही मिळून एकूण गुण 120 गुण उत्तीर्ण होण्यासाठी आवश्यक आहेत.

</div>
      <div className='row'>
<div className='col-md-3'>
          <Button
  style={{ marginTop: '20px' }}
  variant="warning"
  
  type="submit"
  onClick={()=>goBack()}
>मागे जा</Button>
          </div>
</div>
        <div class="row" data-aos="zoom-in">
        <Tab.Container defaultActiveKey="index1">
  <Nav variant="tabs">
    <Nav.Item>
      <Nav.Link eventKey="index1">
      वैयक्तिक निकाल
      </Nav.Link>
    </Nav.Item>
    <Nav.Item>
      <Nav.Link eventKey="index2">
      केंद्रनिहाय गुणवत्ता यादी
      </Nav.Link>
    </Nav.Item>
    {/* <Nav.Item>
      <Nav.Link eventKey="index3">
      सर्व साधारण गुणवत्ता यादी
      </Nav.Link>
    </Nav.Item> */}
  
    <Nav.Item>
      <Nav.Link eventKey="index4">
      शाळानिहाय निकाल
      </Nav.Link>
    </Nav.Item>
  
  </Nav>
  <div>
  <Tab.Content>
    <Tab.Pane eventKey="index1">
       
       <PersonelResult exam_type={'shatabdi'}/>
   
    </Tab.Pane>
    <Tab.Pane eventKey="index2">
    
      <CenterMeritList exam_type={'shatabdi'}/>
     
    </Tab.Pane>
    <Tab.Pane eventKey="index4">
    <SchoolwiseResult exam_type={'shatabdi'}/>
    {/* सदर यादी संध्याकाळी ७ वाजेपर्यंत पब्लिश करण्यात येईल */}
   
  </Tab.Pane>
  </Tab.Content>
  </div>
   {/* <div className='row' style={{marginTop:'50px'}}>
        <h4 style={{color:'green'}}>
        
        ज्या विद्यार्थ्यांच्या गुणांची फेरपडताळणी करावयाची असेल त्यांना दि. 5 एप्रिल 2024 अखेर इ.1ली,2री साठी रू.50/- व इ. 3री ते 7वी साठी प्रत्येक पेपरसाठी रू.50/- इतके शुल्क भरुन फेरपडताळणी करता येईल.  रक्कम संस्थेकडे जमा करणेसाठी सोबत दिलेल्या संस्थेच्या बँक अकौंटसाठीचा QR कोड स्कॅन करुन गुगल पे, फोन पे ने रक्कम भरता येईल रक्कम भरल्या नंतर त्याचा स्क्रीन शाॅट विद्यार्थ्याच्या नावासह 9028831388 या क्रमांकावर वॉटस् अप मेसेज करावा अथवा संस्थेच्या कार्यालयात समक्ष येऊन रक्कम भरता येईल.

         </h4> 
         <div class="col-xl-12 col-md-12 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
         <div ref={galleryRef}>
             <div class="row gallery">
    <div class="col-xl-12 col-md-12" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={qrcode}
        className="glightbox"
        data-title="QR Code"      
      >
              <div className="course-card">
              <img src={qrcode} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           </div>
           </div>
           
          </div>
        </div>  */}
</Tab.Container>
        </div>

      </div>
    </section>
    <section id="services" class="services">
      <div class="container" data-aos="fade-up">

       
        <div class="row">
         
        </div>

      </div>
    </section>

</main>
            </>
        )
    }
  return (
  <WebTemplate>
    {ShatabdiResultScreen()}
  </WebTemplate>
  )
}

export default ShatabdiResult